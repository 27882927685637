.control-switch
  display: inline-block
  position: relative
  overflow: hidden
  vertical-align: middle
  user-select: none
  font-size: 10px
  cursor: pointer
  &__input
    opacity: 0
    position: absolute
    width: 1px
    height: 1px
  &__label
    position: absolute
    top: 0
    font-weight: 600
    color: $lightblue
    z-index: 1
    &--left
      left: 10px
    &--right
      right: 10px
  &__core
    display: block
    position: relative
    box-sizing: border-box
    outline: 0
    margin: 0
    bg-color $support-red
    transition: border-color .3s, background-color .3s
    user-select: none
  &__button
    display: block
    position: absolute
    overflow: hidden
    top: 0
    left: 0
    transform: translate3d(3px, 3px, 0)
    border-radius: 100%
    background-color: $lightblue
    z-index: 2
  &.disabled
    pointer-events: none
    opacity: 0.6
  &.toggled
    .control-switch
      &__core
        bg-color $support-green