.section
  box-sizing border-box
  &--bg
    bg #fff
  &--loading
    position relative
    &:after
      content ''
      width 100%
      h 100%
      top 50%
      position absolute
      left 50%
      transform translate(-50%, -50%)
      bg-color rgba(255, 255, 255, .5)