fieldset[disabled] .control-select
  pointer-events: none
.control-select
  box-sizing: content-box
  display: block
  position: relative
  width: 100%
  height 40px
  text-align: left
  color: #35495e
  font-family: inherit
  font-size: 16px
  touch-action: manipulation
  *
    box-sizing: border-box
  &:focus
    outline: none
  &__spinner
      position: absolute
      right: 1px
      top: 1px
      width: 48px
      height: 35px
      background: #fff
      display: block
      &:before, &:after
        position: absolute
        content: ""
        top: 50%
        left: 50%
        margin: -8px 0 0 -8px
        width: 16px
        height: 16px
        border-radius: 100%
        border-color: #41b883 transparent transparent
        border-style: solid
        border-width: 2px
        box-shadow: 0 0 0 1px transparent

  &__loading-enter-active,
  &__loading-leave-active
      transition: opacity 0.4s ease-in-out
      opacity: 1

  &__loading-enter,
  &__loading-leave-active
      opacity: 0
  &__input,
  &__single
      font-family: inherit
      font-size: 16px
      touch-action: manipulation
      overflow hidden
      position: relative
      display: inline-block
      min-height: 20px
      line-height: 20px
      border: none
      border-radius: 5px
      background: #fff
      padding: 5px 20px 0 0px
      width: calc(100%)
      transition: border 0.1s ease
      box-sizing: border-box
      margin-bottom: 8px
      vertical-align: top



  &__input::placeholder
    color: #35495e

  &__tag ~
    .control-select
      &__input,
      &__single
          width: auto

  &__input:hover,
  &__single:hover
      border-color: #cfcfcf

  &__input:focus
    border-color: #a8a8a8
    outline: none

  &__single
    margin-bottom: 8px
    &:focus
      border-color: #a8a8a8
      outline: none

  &__tags-wrap
    display: inline

  &__tags
    height 40px
    overflow auto
    display: block
    padding: 4px 3px 0px 10px !important
    border-radius: 5px
    border: 1px solid #8897a2
    background: #fff
    &::-webkit-scrollbar
      w 3px
    &::-webkit-scrollbar-track
      box-shadow inset 0 0 5px grey
    &::-webkit-scrollbar-thumb
      background $blue

  &__tag
    position: relative
    display: inline-block
    padding: 7px 26px 4px 10px
    border-radius: 5px
    margin-right: 10px
    color: #fff
    line-height: 1
    background: #41b883
    margin-bottom: 5px
    white-space: nowrap
    overflow: hidden
    max-width: 100%
    text-overflow: ellipsis

  &__tag-icon
    cursor: pointer
    margin-left: 7px
    position: absolute
    right: 0
    top: 0
    bottom: 0
    font-weight: 700
    font-style: initial
    width: 22px
    text-align: center
    line-height: 22px
    transition: all 0.2s ease
    border-radius: 5px
    &:after
      content: "×"
      color: #266d4d
      font-size: 14px
    &:focus,
    &:hover
      background: #369a6e
    &:focus:after,
    &:hover:after
      color: white


  &__select
    line-height: 16px
    display: block
    position: absolute
    box-sizing: border-box
    z-index 2
    width: 40px
    height: 94%
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
    right: 1px
    top: 1px
    padding: 4px 8px
    margin: 0
    text-decoration: none
    text-align: center
    cursor: pointer
    transition: transform 0.2s ease
    &:before
      position: relative
      right: 0
      top: 65%
      color: #999
      margin-top: 4px
      border-style: solid
      border-width: 5px 4px 4px 4px
      border-color: #8897a2 transparent transparent transparent
      content: ""

  &__placeholder
    color: $main
    display: inline-block
    margin-bottom: 7px
    padding-top: 2px

  &__content-wrapper
    position: absolute
    display: block
    background: #fff
    width: 100%
    max-height: 240px
    overflow: auto
    border: 1px solid #e8e8e8
    border-top: none
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 5px
    z-index: 50
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
      w 5px
      h 5px
    &::-webkit-scrollbar-track
      box-shadow inset 0 0 5px grey
    &::-webkit-scrollbar-thumb
      background $blue

  &__content
    list-style: none
    display: inline-block
    padding: 0
    margin: 0
    min-width: 100%
    vertical-align: top

  &__content::webkit-scrollbar
    display: none

  &__element
    display: block

  &__strong
    margin-bottom: 8px
    line-height: 20px
    display: inline-block
    vertical-align: top
    
  &__option
    display: block
    padding: 12px
    min-height: 40px
    line-height: 16px
    text-decoration: none
    text-transform: none
    vertical-align: middle
    position: relative
    cursor: pointer
    &:after
      top: 0
      right: 0
      position: absolute
      line-height: 40px
      padding-right: 12px
      padding-left: 20px
      font-size: 13px
    &--highlight
      background: #808080a8
      outline: none
      color: #35495e
      &:after
        content: attr(data-select)
        background: #808080a8
        color: #35495e
    &--selected
      color: #35495e
      background: #808080a8
      font-weight: 600
      &:after
        content: attr(data-selected)
        color: #35495e
      &.control-select
        &__option
          &--highlight
            background: #808080a8
            color: #35495e
            &:after
              background: #808080a8
              content: attr(data-deselect)
              color: #35495e

    &--disabled
      background: #ededed !important
      color: #a6a6a6 !important
      cursor: text
      pointer-events: none
      &.control-select
        &__option
          &--highlight
            background: #dedede
    &--group
      background: #ededed
      color: #35495e
      &.control-select
        &__option
          &--highlight
            background: #35495e
            color: #35495e
            &:after
              background: #35495e
    &--group-selected
      &.control-select
        &__option
          &--highlight
            background: $background
            color: #fff
            &:after
              background: $background
              content: attr(data-deselect)
              color: #fff

  &--active
    z-index: 50
    &:not(.control-select--above)
      .control-select
        &__current,
        &__input,
        &__tags
          border-bottom-left-radius: 0
          border-bottom-right-radius: 0
    .control-select__placeholder
      display: none
    .control-select__input
      width: 100% !important
  &--above.control-select--active
    .control-select
      &__current,
      &__input,
      &__tags
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
  &--above
    .control-select
      &__content-wrapper
        bottom: 100%
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        border-bottom: none
        border-top: 1px solid #e8e8e8

  &--disabled
    background: #ededed
    opacity: 0.6
    cursor not-allowed
    .control-select
      &__
        &current,
        &select
          cursor not-allowed
          background: #ededed
          color: #a6a6a6

.control-select-enter-active,
.control-select-leave-active
  transition: all 0.15s ease

.control-select-enter,
.control-select-leave-active
  opacity: 0
