.hamburger
  position relative
  h 20px
  w 25px
  cursor pointer
  z-index 100
  display block
  transition .5s all ease
  +md()
    ml 14px
  +xl()
    ml 0
  &__line
    position absolute
    left 50%
    w 100%
    h 3px
    bg-image linear-gradient(95deg,#3358f4,#1d8cf8)
    transition .5s all ease
    transform translateX(-50%)
    &:first-child
      top 0
    &:nth-child(2)
      top 8px
    &:last-child
      top 16px
    &.active
      transition .5s all ease
      &:nth-child(2)
        w 0
      &:first-child,
      &:last-child
        top 50%
      &:first-child
        transform translate(-50%, -50%) rotate(45deg)    
      &:last-child
        transform translate(-50%, -50%) rotate(-45deg)
  &.is-active
    transition .5s all ease
    ml 227px
    +md()
      ml 240px
    +xl()
      ml 0
