xs()
  @media (min-width $xs)
    {block}
sm()
  @media (min-width $sm)
    {block}
md()
  @media (min-width $md)
    {block}
lg()
  @media (min-width $lg)
    {block}
xl()
  @media (min-width $xl)
    {block}
xl2()
  @media (min-width $xl2)
    {block}
    