.control-input
  w 100%
  border-radius 5px
  box-sizing border-box
  outline none
  w 100%
  border 1px solid #8897a2
  h 40px
  bg-color #fff
  pa 5px 5px 5px 10px
  box-sizing border-box
  font-family "Montserrat",sans-serif
  &--textarea
    h 60px
  &--style_default
    color #35495e
    fw 400
  &--md
    max-width 350px
  &--filter-date
    +xl()
      fs 14px
    +xl2()
      fs 16px
input[type="date"]::-webkit-calendar-picker-indicator
  w 100%
input[type="date"],
input[type='number'],
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance none
  margin 0
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator
  display none
  -webkit-appearance none
input[type=number]
  -moz-appearance textfield
input
  &::placeholder
    opacity 1
    color #35495e
    font-family "Montserrat", sans-serif
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder
    opacity 1
    color #35495e
    font-family "Montserrat", sans-serif