.card
  background #fff
  box-shadow 0 1px 15px 0 hsla(0,0%,48.2%,.05)
  pa 20px
  border-radius 5px
  &:after,
  &:before
    display none
  &--head
    +xl()
      h 90px
      display flex
      align-items center
    .row
      &:not(.row--space_none)
        w calc(100% + 16px)
  &--orders
    +xl()
      h 150px