@font-face
  font-family 'Material Icons'
  font-style normal
  font-weight 400
  font-display swap
  src url('../../static/fonts/MaterialIcons-Regular.eot') /* For IE6-8 */
  src local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('../../static/fonts/MaterialIcons-Regular.woff2') format('woff2'),
       url('../../static/fonts/MaterialIcons-Regular.woff') format('woff'),
       url('../../static/fonts/MaterialIcons-Regular.ttf') format('truetype')

.material-icons
  font-family 'Material Icons'
  font-weight normal
  font-style normal
  font-size 24px
  display inline-block
  line-height 1
  text-transform none
  letter-spacing normal
  word-wrap normal
  white-space nowrap
  direction ltr
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing antialiased
  /* Support for Safari and Chrome. */
  text-rendering optimizeLegibility
  /* Support for Firefox. */
  -moz-osx-font-smoothing grayscale
  /* Support for IE. */
  font-feature-settings 'liga'

@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-Black.ttf') format("truetype")
  font-weight 900
  font-style normal
  font-display swap
@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-ExtraBold.ttf') format("truetype")
  font-weight 800
  font-style normal
  font-display swap
@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-Bold.ttf') format("truetype")
  font-weight 700
  font-style normal
  font-display swap
@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-SemiBold.ttf') format("truetype")
  font-weight 600
  font-style normal
  font-display swap
@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-Medium.ttf') format("truetype")
  font-weight 500
  font-style normal
  font-display swap
@font-face
  font-family 'Montserrat'
  src url('../../static/fonts/Montserrat-Regular.ttf') format("truetype")
  font-weight 400
  font-style normal
  font-display swap
