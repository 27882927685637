.table-scroller
  overflow-y hidden
  overflow-x auto
  w 100%
  &::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
    background-color: $background
  &::-webkit-scrollbar
    height 10px
    background-color: $background
  &::-webkit-scrollbar-thumb
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $lightblue
  
