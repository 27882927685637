.close
  bg-color $background
  display flex
  align-items center
  justify-content center
  color $blue
  position absolute
  top -2px
  right -2px
  cursor pointer
  transition .3s all ease
  w 40px
  h 40px
  +md()
    w 46px
    h 46px
  &:hover
    transition .3s all ease
    bg-color $support-red