.proposal
  display flex
  overflow-x auto
  mt 20px
  +md()
    h 517px
    mt 0
    overflow auto
    flex-direction column
    &::-webkit-scrollbar
      w 8px
  +lg()
    overflow visible
  &::-webkit-scrollbar-track
    -webkit-box-shadow inset 0 0 6px rgba(0,0,0,0.3)
    border-radius 5px
    background-color $background
  &::-webkit-scrollbar
    height 10px
    background-color $background
  &::-webkit-scrollbar-thumb
    border-radius 5px
    -webkit-box-shadow inset 0 0 6px rgba(0,0,0,.3)
    background-color $lightblue
  &__item
    pa 21px 8px
    mr 10px
    position relative
    min-width 286px
    bg-color #fff
    cursor pointer
    +md()
      min-width auto
      mr 0
      mb 16px
    +lg()
      pa 21px 20px
    // &--attantion
    //   bg-image linear-gradient(95deg,#ff8d72,#ffae9b)
    &.is-active
      border 1px solid #ffa500
  &__present
    position absolute
    top -14px
    right 3px
    display flex
    justify-content flex-end
    align-items flex-start
    transform rotate(20deg)