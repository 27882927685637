.control
  position relative
  w 100%
  &__meta
    position absolute
    border 1px solid $red
    border-radius 5px
    pa 2px 5px
    bottom 41px
    right 0px
    bg-color #fff
    w 100%
    z-index 99
    &--phone
      bottom 0
    &:after
      content ''
      position absolute
      bottom -6px
      left 35px
      border-left 1px solid $red
      border-top 1px solid $red
      w 10px
      h 10px
      transform rotate(225deg)
      bg-color #fff
  &__help
    mt 0
  &--small
    +lg()
      w 70px