.control-button
  border-radius 5px
  w 100%
  min-height 40px
  position relative
  overflow hidden
  transition .3s width
  &:before
    content ""
    background #fff
    pointer-events none
    height 175px
    left -90%
    opacity .2
    position absolute
    top -50px
    transform rotate(35deg)
    transition all 1s cubic-bezier(0.19, 1, 0.22, 1)
    width 50px
    z-index 0
  &:hover
    &:before
      transition all 1s cubic-bezier(0.19, 1, 0.22, 1)
      left 120%
  &:disabled
    pointer-events auto 
    cursor not-allowed
    &:hover
      &:before
        display none
  &--style_default
    bg-image linear-gradient(95deg,#3358f4,#1d8cf8)
    &:disabled
      &:hover
        bg-color #e3e3e3
  &--style_ghost
    &:before
      background lighten($blue, 70%)
  &--style_green
    bg-image linear-gradient(160deg,#099c2b,#099c2b,#00f2c3)
    background-size 210% 210%
    background-position 100% 0
    background-color #00f2c3
    border 0
    &:before
      background lighten(#00f2c3, 70%)
    &:disabled
      color #fff
  &--small
    will-change width
    transition .3s width
    w calc(100% - 30px)
      