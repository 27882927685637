.link
  color $lightblue
  will-change font-weight
  transition .2s font-weight ease
  &:hover,
  &:focus,
  &:active,
  &.is-active
    color $lightblue
    fw 600
    transition .2s font-weight ease
    box-shadow none
  &:hover:not(:focus)
    fw 600
    box-shadow none
  &.is-active
    .c-icon
      transform rotate(180deg)
      transition .4s all ease
  &--dark
    color $main
    &:hover,
    &:focus,
    &:active
      color $main
  &--white
    color #fff
    &:hover,
    &:focus,
    &:active
      color #fff
  &--w-100
    w 100%
  &--gradient
    bg linear-gradient(95deg,#3358f4,#1d8cf8)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
    .c-icon
      -webkit-text-fill-color $blue
  &--no-hover
    &:hover:not(:focus)
      fw 400
