.mr-15
  mr 15px
.mt-10
  mt 10px
.mt-15
  mt 15px
.mb-15
  mb 15px
.mb-10
  mb 10px
.mb-5
  mb 5px
.ml-5
  ml 5px
.ml-10
  ml 10px
.ml-20
  ml 20px
.mb-5
  mb 5px
.mt-5
  mt 5px
.mt-7
  mt 7px
.mt-25
  mt 25px
.mt-20
  mt 20px
.mb-25
  mb 25px
.mb-20
  mb 20px
.mt-40
  mt 40px
.mt-3rem
  mt 3rem
.mr-5
  mr 5px
.mb-0-lg
  +media(from('lg'))
    mb 0
.mt-auto-lg
  +media(from('lg'))
    mt auto