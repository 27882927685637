.sidebar
  w 100%
  bg-color #fff
  h 100%
  top 0
  box-sizing border-box
  z-index 99
  pa 30px 0
  left -230px
  w 230px
  position absolute
  transition .5s width ease
  transition .5s all ease
  overflow auto
  bg-image linear-gradient(50deg,#3358f4,#1d8cf8)
  box-shadow 0 2px 22px 0 rgba(0,0,0,.1), 0 4px 20px 0 rgba(0,0,0,.15)
  &:after
    +xl()
      content ""
      position absolute
      border-left 5px solid transparent
      border-right 5px solid transparent
      border-bottom 5px solid #1d8cf8
      top -5px
      left 40px
      -webkit-transform translate(-50%)
      transform translate(-50%)
  +xl()
    position fixed
    top 0
    left 0
    w 80px
    display block
    height calc(100vh - 160px)
    min-height 450px
    border-radius 5px
    ml 20px
    mt 139px
    overflow visible
  &__wrapper
    display flex
    flex-direction column
    align-items center
    min-height 400px
    h 100%
  &__text
    position relative
    +xl()
      visibility hidden
  &.is-active
    left 0
  &.is-active,
  &:hover
    +xl()
      transition .5s width ease
      left 0
      w 230px
    .sidebar
      &__text
        transition .5s visibility .4s ease
        visibility visible
.sidebar-enter-active,
.sidebar-leave-active
  transition all .5s ease
.sidebar-enter,
.sidebar-leave-to
  transform translateX(100%)
  opacity 0