.header
  bg-color $lightblue
  z-index 999
  border-bottom 1px solid $blue
  bg-image linear-gradient(0deg,#3358f4,#1d8cf8)
  &__wrapper
    h 80px
    display flex
    align-items center
    justify-content space-between
    &--end
      justify-content flex-end
    &--driver
      h auto
      +sm()
        min-h 40px
  &__block
    display flex
    align-items center
    justify-content space-between
    &--main
      justify-content center
      +lg()
        w 330px
      +xl()
        w 372px
        justify-content space-between
    &--end
      justify-content flex-end
  &--operator
    bg-image none
    h 100%
    border 0
    bg-color transparent
    +xl()
      position fixed
    .header
      &__wrapper
        h 65px
        position absolute
        +xl()
          h 138px
  &--driver
    pa 10px 0