.icon
  fs 20px
  color $lightblue
  &--absolute
    position absolute
    top 50%
    pointer-events none
    transform translateY(-50%)
    display flex
    align-items center
    justify-content center
  &--right
    right 3px
  &--filter
    +xl() 
      display none
    +xl2()  
      display block