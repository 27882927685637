.c-table
  .control-select
    fs 12px
    &__option,
    &__single
      fs 12px
    &__placeholder
      pt 4px
      fs 12px
    &__select
      &:before
        top 60%
  &__head
    bg-image linear-gradient(95deg,#3358f4,#1d8cf8)
    color #fff
    .c-table
      &__cell
        fw 700
  &__row
    &--pointer
      cursor pointer
  &__body
    &--table
      .control-select
        &__single
          pr 12px
  &__cell
    fs 12px
    pa 5px
    +xl()
      fs 12px
    &:last-child
      pr 5px
    &--driver
      +lg()
        width 200px
      +xl2()
        w 230px
    &--address
      +lg()
        width 150px
      +xl2()
        w 270px
    &--state
      +lg()
        width 130px
      +xl2()
        w 150px
    &--phone
      +lg()
        width 108px
    &--create
      +lg()
        width 135px
    &--payment
      +lg()
        width 70px
  &--big
    .c-table
      &__cell
        +md()
          pa 18px
          fs 14px
        +xl()
          fs 14px
  &--driver
    .c-table
      &__cell
        pa 7px
        +md()
          pa 7px
        +lg()
          pa 18px
          w 65%
        &:nth-child(2)
          +lg()
            w 35%
  &--total
    .c-table
      &__row
        &:last-child
          td
            fw 700
