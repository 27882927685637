@font-face
	font-family: "svgfont"
	font-display: swap
	src: url('./../fonts/svgfont.eot')
	src: url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'),
			 url('./../fonts/svgfont.woff') format('woff'),
			 url('./../fonts/svgfont.woff2') format('woff2'),
			 url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-essential-light-30-document= "\EA01"
$icon-essential-light-85-users= "\EA02"
$icon-essential-light-48-clipboard= "\EA03"
$icon-essential-light-44-phone= "\EA04"
$icon-essential-light-99-statistic-pie= "\EA05"
$icon-essential-light-86-clock= "\EA06"
$icon-essential-light-84-calendar= "\EA07"
$icon-essential-light-89-map= "\EA08"
$icon-save= "\EA09"


$icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend $icon
	display: inline-block

.icon-essential-light-30-document:before
	content: $icon-essential-light-30-document
.icon-essential-light-85-users:before
	content: $icon-essential-light-85-users
.icon-essential-light-48-clipboard:before
	content: $icon-essential-light-48-clipboard
.icon-essential-light-44-phone:before
	content: $icon-essential-light-44-phone
.icon-essential-light-99-statistic-pie:before
	content: $icon-essential-light-99-statistic-pie
.icon-essential-light-86-clock:before
	content: $icon-essential-light-86-clock
.icon-essential-light-84-calendar:before
	content: $icon-essential-light-84-calendar
.icon-essential-light-89-map:before
	content: $icon-essential-light-89-map
.icon-save:before
	content: $icon-save
