.notification
  position absolute
  top 20px
  left 50%
  transform translateX(-50%)
  z-index 99
  +lg()
    min-width 400px
  &__item
    border-radius 3px
    bg-image linear-gradient(0deg,#3358f4,#1d8cf8)
    color #fff
    pa 10px
    box-shadow 0 14px 26px -12px rgba(0,188,212,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,188,212,.2)
    display flex
    justify-content center
    align-items center
    &+&
      mt 20px
  &__text
    ml 20px