.preloader 
  position fixed
  z-index 999
  width 100px
  pt 150px
  left 50%
  transform translateX(-50%)
  &__svg 
    animation rotate 2s linear infinite
    h 100%
    w 100%
  .path
    stroke-dasharray 1, 200
    stroke-dashoffset 0
    animation dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite
    stroke-linecap round
    
@keyframes rotate
  100%
    transform rotate(360deg)
@keyframes dash
  0%
    stroke-dasharray 1, 200
    stroke-dashoffset 0
  50%
    stroke-dasharray 89, 200
    stroke-dashoffset -35px
  100%
    stroke-dasharray 89, 200
    stroke-dashoffset -124px
@keyframes color
  100%, 0%
    stroke $blue
  40%
    stroke $lightblue
  66%
    stroke $blue
  80%, 90%
    stroke $lightblue
