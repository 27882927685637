.v
  &--modal
    border-radius 0 !important
    box-shadow 0 28px 28px 2px rgba(55, 75, 85, 0.08) !important
    border 2px solid rgba(8, 68, 124, 0.03) !important
    pa 40px 20px !important
    ma 0 auto !important
    w calc(100% - 30px) !important
    left 0 !important
    max-h 90vh !important 
    overflow-y auto !important
    overflow-x hidden !important
    &::-webkit-scrollbar
      w 5px
    &::-webkit-scrollbar-track
      box-shadow inset 0 0 5px grey
    &::-webkit-scrollbar-thumb
      background $blue
    +sm()
      pa 30px !important
      pr 45px !important
      w 570px !important
  &--modal-order
    .v--modal
      +lg()
        w 770px !important
  &--modal-overlay-phone
    background transparent !important
    h 0 !important
    .v--modal
      ml auto !important
      border 1px solid $blue !important
      border-radius 5px !important
      mr 16px !important
      +media(from('sm'))
        w 390px!important
      +media(from("md"))
        mr 30px !important
      +media(from("xl"))
        mr 40px !important
  &--modal-history
    .v--modal
      w calc(100% - 32px) !important
      h 100% !important
      pa 0 !important
.v--modal-overlay-phone
  &:nth-child(2)
    .v--modal
      +media(from('sm'))
        mt 180px !important
      +media(from("md"))
        mt 180px !important
      +media(from("xl"))
        mt 180px !important
  &:nth-child(3)
    .v--modal
      +media(from('sm'))
        mt calc(180px * 2) !important
      +media(from("md"))
        mt calc(180px * 2) !important
      +media(from("xl"))
        mt calc(180px * 2) !important
  &:nth-child(4)
    .v--modal
      +media(from('sm'))
        mt calc(180px * 3) !important
      +media(from("md"))
        mt calc(180px * 3) !important
      +media(from("xl"))
        mt calc(180px * 3) !important
  &:nth-child(5)
    .v--modal
      +media(from('sm'))
        mt calc(180px * 4) !important
      +media(from("md"))
        mt calc(180px * 4) !important
      +media(from("xl"))
        mt calc(180px * 4) !important