.container
  box-sizing border-box
  width 100%
  &--fit_2xl
    max-width $xl2
    +md()
      pa 0 30px
    +xl2()
      pa 0 16px
  &--header
    +xl()
      pr 40px
      pl 48px
.operator
  .container
    &--fit_2xl
      +xl()
        pl 120px
        pr 40px
        mr 0
        width 100%
        max-width 100%
      +xl2()
        width 100%
        max-width 100%
        pl 120px
        pr 40px