.navigation
  &--desktop
    display none
    +xl()
      display block
  &--mobile
    order 2
    +xl()
      display none
  &__wrapper
    display flex
    &--mobile
      mt 30px
      flex-direction column
    &--driver
      flex-wrap wrap
  &__item
    display flex
    align-items center
    color #fff
    fs 14px
    &:hover,
    &:focus,
    &:active
      color #fff
    +xl()
      color #fff
      &:hover,
      &:focus,
      &:active
        color #fff
    &--sidebar
      display flex
      justify-content flex-start
      pa 0 16px
      w calc(100% - 10px)
      ml 10px
      h 40px
      box-sizing border-box
    &--driver
      fs 1rem
      &+&
        ml 0

    &--mobile
      &+&
        mt 20px
        ml 0
    &--time
      w 60px
  &__icon
    fs 22px
    fw 600
    w 50px
    max-width 100%
    &.c-icon
      fw 400