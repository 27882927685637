.justify-content
  &--center
    justify-content center
  &--justify
    justify-content space-between
  &--end
    justify-content flex-end
  &--lg-end
    +lg()
      justify-content flex-end
  &--md-end
    +md()
      justify-content flex-end