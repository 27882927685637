.filter
  mt 5px
  +media(from('lg'))
    mt 0
  &__clear
    position absolute
    color $blue
    cursor pointer
    top 50%
    right 10px
    transform translateY(-50%)
    +media(from('md'))
      right 0

