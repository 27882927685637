.present
  position relative
  &__number
    position absolute
    top -6px
    right -12px
    width 18px
    font-size 10px
    height 18px
    display flex
    justify-content center
    align-items center
    border-radius 50%
    border 2px solid $red
    color $red
    +lg()
      right -9px
    +xl()
      right -12px