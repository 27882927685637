body
  font-family "Montserrat", sans-serif
  color $main
  bg-color $background
  &.is-active
    overflow hidden
main
  transform translate3d(0,0,0)
  transition .5s transform ease
  +xl()
    transform none
    transition none
  &.is-active
    transform translateX(0)
    transition .5s transform ease
    transform translate3d(230px,0,0)
    +xl()
      transform translateX(0)
.operator
  .main
    pt 45px
    +xl()
      pt 0
textarea
  resize none
a
  color inherit 
  text-decoration none
.v--modal-block-scroll
  main
    transform none