.sort
  cursor pointer
  display flex
  &__icon
    .c-icon
      fs 20px
      position absolute
      transform rotate(0deg)
    &.is-active
      .c-icon
        transform rotate(180deg)
