.control-select
  .control-checkbox
    pointer-events none
  &--proposal
    +xl()
      width 130px
    .control-select
      &__tags
        pr 30px
  &--payment
    +lg()
      w 130px
    +xl()
      w 130px
  &--order
    +lg()
      width 325px
  &--header
    width 188px
    +xl()
      w 370px
    +xl2()
      w 500px
  &__select
    w 25px
  &__option,
  &__single
    fs 16px
  &--driver
    h 60px
    +lg()
      h 40px
    .control-select
      &__tags
        h 60px
        display flex
        align-items center
        +lg()
          h 40px
    
  &__input,
  &__single,
  .control-input
    bg none
  &--success
    .control-input,
    .control-select__input,
    .control-select__single
      bg linear-gradient(95deg,#3358f4,#1d8cf8)
      color #fff
    .control-select
      &__select
        &:before
          border-color #fff transparent transparent
  &--warning
    .control-input,
    .control-select__input,
    .control-select__single
      bg linear-gradient(95deg,#ec250d,#fd5d93)
      color #fff
    .control-select
      &__select
        &:before
          border-color #fff transparent transparent
  &--attantion
    .control-input,
    .control-select__input,
    .control-select__single
      bg linear-gradient(95deg,#ff8d72,#ffae9b)
      color #fff
    .control-select
      &__select
        &:before
          border-color #fff transparent transparent
